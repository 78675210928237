import { FC } from 'react';
import { Lato as GoogleFont } from 'next/font/google';
import Head from 'next/head';

export const lato = GoogleFont({
    weight: ['400', '700', '900'],
    style: ['normal', 'italic'],
    subsets: ['latin'],
    display: 'swap',
});

export const Lato: FC<{}> = () => (
    <Head>
        <style
            key="fontfaces-lato"
            className={lato.className}
            dangerouslySetInnerHTML={{
                __html: `
                :root {
                    --font-family-lato: Lato, ${lato.style.fontFamily}, Helvetica, Arial, sans-serif;
                }`,
            }}
        />
    </Head>
);
